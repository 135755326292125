@import "primeicons/primeicons.css";

@import "primeflex/primeflex.css";

/* You can add global styles to this file, and also import other style files */
html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */

@font-face {
  font-family: "Niageng";
  src: url("./assets/NIAGENG.ttf");
}
